<template>
	<div class="form-group">
		<validation-provider :rules="{ required: true }">
			<multiselect
				@search-change="handleSearchOptionsDebounce"
				v-model="selected"
				:options="options"
				:close-on-select="closeOnSelect ? closeOnSelect : false"
				:option-height="40"
				:showLabels="false"
				:searchable="true"
				:multiple="multiple ? multiple : true"
				:placeholder="placeholder ? placeholder : 'Selecionar'"
				openDirection="bottom"
				class="with-border multiple"
			>
				<template slot="caret">
					<div class="chevron">
						<ChevronDown />
					</div>
				</template>
				<template slot="selection" slot-scope="{ values }" class="selectedOptions">
					<span v-bind:class="{'selectedOptions': values.length > 0}">
						<p v-if="allSelected()">{{ label }}</p>
						<p v-else-if="values.indexOf('Todos') !== -1">{{values.slice(values.indexOf("Todos")).join(', ')}}</p>
						<p v-else>{{values.join(', ')}}</p>
					</span>
				</template>
				<template slot="noOptions"> Nenhuma opção </template>
				<template slot="noResult"> Nenhum resultado </template>
			</multiselect>
		</validation-provider>
	</div>
</template>

<script>
import { debounce } from 'lodash';
import { getFilterProcedimento, getFilterConvenio } from '@/utils/filtersLocalStorageManager'
import { getCurrentClinic } from '../utils/localStorageManager'
import itemApi from '@/modules/items/api.js'

export default {
	components: { 
		ChevronDown: () => import('@/assets/icons/chevron-down.svg')
	},
	props: {
		label: {
			default: 'Todos',
			type: String
		},
		type: String,
		placeholder: String,
		multiple: Boolean,
		closeOnSelect: Boolean,
		procedureTypes: Array
	},
	created() {
    	this.handleSearchOptionsDebounce = debounce(this.handleSearchOptions, 300);
	},
	data() {
		const opts = this.type === 'procedures' ? getFilterProcedimento() : (this.type === 'healthPlans' ? getFilterConvenio() : ["Todos"]) 
		const hasAll = opts.find(el => el === "Todos")
			if(!hasAll) {
				opts.unshift("Todos")
			}
		return {
			clinic: getCurrentClinic(),
			options: opts,
			selected: this.type === 'procedures' ? getFilterProcedimento() : (this.type === 'healthPlans' ? getFilterConvenio() : ["Todos"]),
			canChangeSelected: true,
      handleSearchOptionsDebounce: Function,
		}
	},
	mounted() {
		this.selected = ['Todos']
	},
	methods: {
		allSelected() {
			return !(this.selected.length < this.options.length)
		},
		async handleSearchOptions(query) {
			switch(this.type) {
				case 'patients': return await this.searchPatients(query)
				case 'professionals': return await this.searchProfessionals(query)
				case 'healthPlans': return await this.searchHealthPlans(query)
				case 'procedures': return await this.searchProcedures(query)
				case 'doctors': return await this.searchDoctors(query)
				case 'rooms': return await this.getClinicRooms(query)
			}
		},
		async searchPatients(query) {
			await this.api.searchPatients(this.clinic.id, query)
				.then(res => {
					this.options = res.data.map(patient => {
						return patient.name
					})
					this.options.unshift("Todos")
					if (this.selected.indexOf("Todos") !== -1)
						this.selected = this.options
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		async searchDoctors(query) {
			await this.api.getClinicDoctors(this.clinic.id)
				.then(({ data }) => {
					this.options = data.doctors.map(doctor => {
						return doctor.name
					})
					this.options.unshift("Todos")
					if (this.selected.indexOf("Todos") !== -1) {
						this.selected = this.options
					}
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		async searchProfessionals(query) {
			await this.api.getProfessionals(1, this.clinic.id, query)
				.then(res => {
					this.options = res.data.data.map(professional => {
						return professional.name
					})
					this.options.unshift("Todos")
					if (this.selected.indexOf("Todos") !== -1) {
						this.selected = this.options
					}
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		async getClinicRooms (query) {
			await this.api.getClinicRooms(this.clinic.id, 1, query, null)
				.then(res => {
					this.options = res.data.data.map(room => {
						return room.name
					})
					this.options.unshift("Todos")
					if (this.selected.indexOf("Todos") !== -1) {
						this.selected = this.options
					}
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		async searchHealthPlans(query) {
			await this.api.getClinicHealthPlans(this.clinic.id, 1, query)
				.then(res => {
					this.options = res.data.data.map(plan => {
						return plan.health_plan.fantasy_name ?? plan.health_plan.company_name
					})
					this.options.unshift("Todos")
					if (this.selected.indexOf("Todos") !== -1)
						this.selected = this.options
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		async searchProcedures(query) {
			let types = []
			if (this.procedureTypes?.length || this.procedureTypes?.indexOf("Todos os procedimentos") !== -1) {
				const typesTranslation = {
					"Consulta": "APPOINTMENT",
					"Centro Cirúrgico": "CIRURGIC",
					"Exame": "EXAM",
					"Procedimento": "PROCEDURE",
					"Retorno": "RETURN",
					"Telemedicina": "TELEMEDICINE",
          			'Centro cirúrgico': 'SURGICAL'
				}

				if (!this.procedureTypes || this.procedureTypes.length) {
					Object.keys(typesTranslation).map(key => {
						types.push(typesTranslation[key])
					})
				} else {
					this.procedureTypes.map(key => {
						types.push(typesTranslation[key])
					})
				}
				this.options.unshift("Todos")
				if (this.selected.indexOf("Todos") !== -1)
					this.selected = this.options
			} else {
				types = null
			}

      try {
			const response = await itemApi.searchItems(this.clinic.id, query, types)
			console.log('asdasdasd');
			this.options = response.data.map(procedure => {
			return procedure.name
        })
        this.options = [...new Set(this.options)]
        this.options.unshift("Todos")
			if (this.selected.indexOf("Todos") !== -1) {
				this.selected = this.options
			}
		} catch (error) {
			this.$toast.error(error.message)
		}
		},
		changeSelected(value) {
			this.$emit('onChange', value)
		},
	},
	watch: {
		selected: function (newValue, oldValue) {
			if (this.canChangeSelected) {
				if (oldValue.indexOf("Todos") === -1 && newValue.indexOf("Todos") >= 0) {
					this.canChangeSelected = false
					this.selected = this.options.map(el => { return el })
					this.changeSelected(null)
					setTimeout(() => {
						this.canChangeSelected = true
					}, "200")
				} else if (oldValue.indexOf("Todos") >= 0 && newValue.indexOf("Todos") === -1) {
					this.canChangeSelected = false
					this.selected = []
					this.changeSelected([])
					setTimeout(() => {
						this.canChangeSelected = true
					}, "200")
				}
				else if (this.allSelected()) {
					this.changeSelected(this.selected.filter(el => el !== "Todos"))
				} else {
					this.canChangeSelected = false
					this.selected = this.selected.filter(el => el !== "Todos")
					this.changeSelected(this.selected);
					setTimeout(() => {
						this.canChangeSelected = true
					}, "200")
				}
			}
			const hasAll = this.selected.find(el => el === "Todos")
			if(this.type === 'procedures') {
				localStorage.setItem('filterProcedimento', JSON.stringify(hasAll ? ['Todos'] : this.selected))
			} else if (this.type === 'healthPlans') {
				localStorage.setItem('filterConvenio', JSON.stringify(hasAll ? ['Todos'] : this.selected))
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.selectedOptions {
	width: 100%;
	display: flex;
	flex-direction: row;
	line-height: 100%;
	overflow: hidden;
	text-align: start !important;
	height: 50% !important;
}

p {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	align-items: center;
	color: var(--type-active);
}
</style>
